import * as React from "react";
import GoogleMapReact from "google-map-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Spacer from "../components/Spacer";
import ImageBlock from "../components/ImageBlock";
import Pc from "../images/pc.jpg";
import Bethlem from "../images/bethlem.jpg";
import Fbc from "../images/fbc.jpg";
import Namb from "../images/namb.jpg";
import Tct from "../images/tct.jpg";
import imageMap from '../images/map.png'


// markup
const ContactoPage = () => {
  // Initialize and add the map
  const defaultCenter = {
    lat: 59.955413,
    lng: 30.337844,
  };


  const [sending, setSending] = React.useState(false);
  const [enviado, setEnviado] = React.useState(false);



  function formSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    setSending(true)

    console.log(['formData', formData])

    // fetch("https://getform.io/f/551b597a-b5f2-430a-b285-80e6edece4b6", {
    //   method: "POST",
    //   body: formData,
    //   headers: {
    //     "Accept": "application/json",
    //   },
    // })
    //   .then(response => { setSending(false); setEnviado(true) })
    //   .catch(error => { setSending(false); setEnviado(false) })
  }

  return (
    <>
      <Header></Header>
      <div className="Index-page-content">
        <h1 style={{ textAlign: "center" }}>Contacto</h1>
        <div className="row sqs-row">
          <div className="row sqs-row">
            <h2>Servicio </h2>
            <h3 style={{ marginBottom: 0, color: "black" }}>
              {" "}
              <em>Domingos 10:45am </em>
            </h3>
            <h3 style={{ marginTop: 0, color: "black" }}>
              <em>Dirección 10298 NW 46th St Sunrise FL 33351</em>
            </h3>
            <h3 style={{ marginTop: 0 }}>
              Horario de Oficina: Martes-Viernes 9-5pm{" "}
            </h3>
          </div>

          <Spacer />
          <div className="sqs-block-content">
            <hr />
          </div>
          <Spacer />

          <div className="col sqs-col-6 span-6">
            <div className="row sqs-row">
              <h2>Lugar </h2>
              <div style={{ height: "300px", width: "300px" }}>
                <a
                  href="https://www.google.com/maps/place/10298+NW+46th+St,+Sunrise,+FL+33351/@26.18143,-80.2892437,17z/data=!3m1!4b1!4m5!3m4!1s0x88d908ad823ba101:0xa97fe5df8fd4965e!8m2!3d26.1814252!4d-80.287055"
                  target="_blank"
                  rel="noreferrer"
                >

                  <img
                    style={{ width: '100%' }}
                    className="thumb-image loaded"
                    data-component-key="idv1.i.56f049d6b09f958b0995b18e"
                    data-src={imageMap}
                    data-image={imageMap}
                    data-image-dimensions="1000x1230"
                    data-image-focal-point="0.5,0.5"
                    alt=""
                    data-load="false"
                    data-image-id="56f049d6b09f958b0995b18e"
                    data-type="image"
                    data-image-resolution="1500w"
                    src={imageMap}
                  />
                </a>

                {/* <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCTJlAo9Xgeqp78Jnl6WPeATlB8oXaHcRc",
                  }}
                  defaultCenter={defaultCenter}
                  defaultZoom={10}
                >
                  <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                  />
                </GoogleMapReact> */}
              </div>
            </div>
          </div>
          <div className="col sqs-col-6 span-6">
            <div
              className="sqs-block form-block sqs-block-form"
              data-block-type="9"
              id="block-yui_3_17_2_3_1457471315566_7233"
            >
              <div className="sqs-block-content">
                <div className="form-wrapper">
                  <div className="form-inner-wrapper">
                    <form method="POST" id="form" accept-charset="UTF-8" onSubmit={formSubmit}>
                      <div className="field-list clear">
                        <div
                          id="email-yui_3_17_2_1_1457471315566_9780"
                          className="form-item field email required"
                        >
                          <label
                            className="title"
                            for="email-yui_3_17_2_1_1457471315566_9780-field"
                          >
                            Correo electronico
                            <span className="required" aria-hidden="true">
                              *
                            </span>
                          </label>
                          <input className="field-element" type="email" name="email" placeholder="Tu correo electronico" />
                        </div>

                        <fieldset
                          id="name-yui_3_17_2_1_1457471315566_9441"
                          className="form-item fields name required"
                        >
                          <legend className="title">
                            Nombre
                            <span className="required" aria-hidden="true">
                              *
                            </span>
                          </legend>

                          <div className="field first-name">
                            <label className="caption">
                              <input
                                data-title="First" className="field-element field-control" x-autocompletetype="given-name" type="text" name="first-name" placeholder="Tu primer nombre" />
                              <span className="caption-text">Nombre</span>
                            </label>
                          </div>
                          <div className="field last-name">
                            <label className="caption">
                              <input
                                data-title="First" className="field-element field-control" x-autocompletetype="given-name" type="text" name="last-name" placeholder="Tu apellido" />

                              <span className="caption-text">Apellido</span>
                            </label>
                          </div>
                        </fieldset>

                        <div
                          id="website-yui_3_17_2_1_1457471315566_21376"
                          className="form-item field "
                        >
                          <label
                            className="title"
                            for="website-yui_3_17_2_1_1457471315566_21376-field"
                          >
                            Mensaje
                          </label>

                          <textarea
                            className="field-element"
                            type="text"
                            spellcheck="false"
                            name="message"
                          />
                        </div>
                      </div>

                      <div
                        data-animation-role="button"
                        className="form-button-wrapper form-button-wrapper--align-left"
                      >
                        <input type="hidden" name="_gotcha" style={{ display: 'none' }} />
                        <input
                          className="button sqs-system-button sqs-editable-button"
                          type="submit"
                          value={sending ? "Enviando..." : "Enviar"}
                          disabled={sending}
                        />
                      </div>

                      {enviado && (
                        <div className="hidden form-submission-text">Gracias por contactarnos, estaremos respondiendote lo mas pronto posible.</div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col sqs-col-6 span-6">
            <div
              className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
              data-block-type="21"
              id="block-yui_3_17_2_25_1458742771823_11462"
            >
              <div className="sqs-block-content">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default ContactoPage;
