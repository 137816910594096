import React from "React";

const ImageBlock = (props) => {
  const { title, image, link } = props;
  return (
    <div className="col sqs-col-4 span-4">
      <div className="sqs-block image-block sqs-block-image sqs-text-ready">
        <div className="sqs-block-content">
          <div className="image-block-outer-wrapper layout-caption-below design-layout-inline">
            <figure className="sqs-block-image-figure intrinsic">
              <div
                className="image-block-wrapper"
                data-animation-role="image"
                data-animation-override=""
              >
                <div className="sqs-image-shape-container-element has-aspect-ratio">
                  <img className="thumb-image loaded" src={image} />
                </div>
              </div>
            </figure>
          </div>
        </div>
      </div>
      <div className="sqs-block html-block sqs-block-html">
        <div className="sqs-block-content">
          <h4>{title}</h4>
          <p>
            <a href={link} target="_blank">
              Website >
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageBlock;
